import { type StateCreator } from 'zustand';

import {
  getVideoManagerActionType as actionType,
  VideoManagerStateKey as StateKey,
} from '@/store/constants';
import { type VideoManagerSlice } from '@/store/typings';

export const createVideoManagerSlice: StateCreator<
  VideoManagerSlice,
  [['zustand/devtools', never]],
  []
> = (set) => ({
  deleteVideoManagerEntry: async (playerId) => {
    set(
      (state) => {
        delete state[StateKey][playerId];
        return {
          [StateKey]: state[StateKey],
        };
      },
      false,
      actionType,
    );
  },
  resetVideoManagerData: async () => {
    set(
      () => ({
        [StateKey]: {},
      }),
      false,
      actionType,
    );
  },
  setVideoManagerData: async (videoData) => {
    set(
      (state) => {
        const prevState = state[StateKey][videoData.playerId] || {};
        return {
          [StateKey]: {
            ...state[StateKey],
            [videoData.playerId]: {
              ...prevState,
              ...videoData,
            },
          },
        };
      },
      false,
      actionType,
    );
  },

  [StateKey]: {},
});
