import { env } from '@/config';

import {
  type AddCommentPost,
  type DeleteCommentPost,
  type EditCommentPost,
  type UpdateProfilePost,
} from '@/types/Comments';

import { commentError } from '@/utils/comments/constants';

export const createJwt = async (userId: string) => {
  try {
    const path = `${env.proxy.url}/jwt/create`;

    const fetchConfig = {
      body: JSON.stringify({ userId }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    };

    const response = await fetch(path, fetchConfig);

    if (response.ok) {
      const data = await response.text();
      return data;
    } else {
      throw new Error(`Failed to create JWT: ${response.statusText}`);
    }
  } catch (error) {
    console.error(error);
    return '';
  }
};

export async function sendComment(
  payload: AddCommentPost | EditCommentPost | DeleteCommentPost,
  token: string,
) {
  try {
    const path = `${env.userApi.url}/interaction`;

    const fetchConfig = {
      body: JSON.stringify(payload),
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    };

    const response = await fetch(path, fetchConfig);

    const body = await response.json();
    return body;
  } catch (error) {
    console.error(error);
    return {
      error: true,
      message: commentError.publishError,
    };
  }
}

export async function fetchComments({
  id,
  token,
  type,
  userId,
}: {
  id: string;
  token: string;
  type: string;
  userId: string;
}) {
  try {
    const path = `${env.userApi.url}/interaction`;

    const payload = {
      action: 'FETCH',
      id,
      type,
      userId,
    };

    const fetchConfig = {
      body: JSON.stringify(payload),
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    };

    const response = await fetch(path, fetchConfig);

    if (response.ok) {
      const data = response.json();
      return data;
    } else {
      throw new Error(`Failed to fetch comments: ${response.statusText}`);
    }
  } catch (error) {
    console.error(error);
    return {};
  }
}

export async function fetchUserProfile(token: string) {
  try {
    const path = `${env.userApi.url}/profile`;

    const fetchConfig = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    };

    const response = await fetch(path, fetchConfig);

    if (response.ok) {
      const data = response.json();
      return data;
    } else {
      throw new Error(`Failed to fetch user profile: ${response.statusText}`);
    }
  } catch (error) {
    console.error(error);
    return {};
  }
}

export async function updateUserProfile(
  payload: UpdateProfilePost,
  token: string,
) {
  try {
    const path = `${env.userApi.url}/profile`;

    const fetchConfig = {
      body: JSON.stringify(payload),
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    };

    const response = await fetch(path, fetchConfig);

    if (response.ok) {
      const data = response.json();
      return data;
    } else {
      throw new Error(`Failed to update user profile: ${response.statusText}`);
    }
  } catch (error) {
    console.error(error);
    return {};
  }
}
