import { BuyersGuideConstants } from '@/constants';
import { type StateCreator } from 'zustand';

import {
  getCuratedImagesActionType as actionType,
  CuratedImagesStateKey as StateKey,
} from '@/store/constants';
import { type CuratedImagesSlice } from '@/store/typings';

export const createCuratedImagesSlice: StateCreator<
  CuratedImagesSlice,
  [['zustand/devtools', never]],
  []
> = (set, get) => ({
  getCuratedImages: async (serverAction, variables) => {
    const {
      bodyStyle = 'suv',
      filterBuyersGuide = true,
      limit = 100,
      makeSlug = '',
      modelSlug = '',
      year = 0,
    } = variables;
    // @note: There is [an
    // issue](https://github.com/cypress-io/cypress/issues/28185) using server
    // actions with Cypress component testing. Passing the server action as
    // parameter to `getCuratedImages` seems to resolve it as a temporary
    // solution until we move away from Cypress.
    const data = await serverAction({
      bodyStyle,
      filterBuyersGuide,
      limit,
      makeSlug,
      modelSlug,
      year,
    });

    set(
      () => ({
        [StateKey]: {
          ...get().CuratedImages,
          [bodyStyle as string]: data.curatedImages,
        },
      }),
      false,
      actionType,
    );

    return data;
  },
  [StateKey]: { [BuyersGuideConstants.BodyStyle.Suv]: undefined },
});
