import { type StateCreator } from 'zustand';

import {
  resetGptImpressionViewableTimeActionType,
  setGptImpressionViewableTimeActionType,
  setGptRefreshIntervalIdActionType,
  setGptSlotRenderEndedActionType,
  GooglePublisherTagStateKey as StateKey,
  SubStateKey,
} from '@/store/constants';
import { type GooglePublisherTagSlice } from '@/store/typings';

export const createGooglePublisherTagSlice: StateCreator<
  GooglePublisherTagSlice,
  [['zustand/devtools', never]],
  []
> = (set) => ({
  setGptSlotRenderEnded: (event) => {
    const { slot } = event;

    if (slot) {
      const slotId = slot.getSlotElementId();

      set(
        (state) => ({
          [StateKey]: {
            // Spread to preserve existing state
            ...state[StateKey],
            [SubStateKey.SlotRenderEnded]: {
              // Spread to preserve existing sub-state
              ...state[StateKey][SubStateKey.SlotRenderEnded],
              [slotId]: true,
            },
          },
        }),
        false,
        setGptSlotRenderEndedActionType,
      );
    }
  },

  setGptImpressionViewableTime: (event, date) => {
    const { slot } = event;

    if (slot) {
      const slotId = slot.getSlotElementId();

      set(
        (state) => ({
          [StateKey]: {
            // Spread to preserve existing state
            ...state[StateKey],
            [SubStateKey.ImpressionViewableTime]: {
              // Spread to preserve existing sub-state
              ...state[StateKey][SubStateKey.ImpressionViewableTime],
              [slotId]: date,
            },
          },
        }),
        false,
        setGptImpressionViewableTimeActionType,
      );
    }
  },

  resetGptImpressionViewableTime: (slotId) => {
    set(
      (state) => ({
        [StateKey]: {
          // Spread to preserve existing state
          ...state[StateKey],
          [SubStateKey.ImpressionViewableTime]: {
            // Spread to preserve existing sub-state
            ...state[StateKey][SubStateKey.ImpressionViewableTime],
            [slotId]: undefined,
          },
        },
      }),
      false,
      resetGptImpressionViewableTimeActionType,
    );
  },

  setGptRefreshIntervalId: (
    event: googletag.events.ImpressionViewableEvent,
    intervalId: NodeJS.Timeout,
  ) => {
    const { slot } = event;

    if (slot) {
      const slotId = slot.getSlotElementId();

      set(
        (state) => ({
          [StateKey]: {
            // Spread to preserve existing state
            ...state[StateKey],
            [SubStateKey.RefreshIntervalId]: {
              // Spread to preserve existing sub-state
              ...state[StateKey][SubStateKey.RefreshIntervalId],
              [slotId]: intervalId,
            },
          },
        }),
        false,
        setGptRefreshIntervalIdActionType,
      );
    }
  },

  [StateKey]: {
    [SubStateKey.SlotRenderEnded]: {},
    [SubStateKey.ImpressionViewableTime]: {},
    [SubStateKey.RefreshIntervalId]: {},
  },
});
