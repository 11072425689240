import { type StateCreator } from 'zustand';

import {
  setNavigationActionType as actionType,
  NavigationStateKey as StateKey,
} from '@/store/constants';
import { type NavigationSlice } from '@/store/typings';

export const createNavigationSlice: StateCreator<
  NavigationSlice,
  [['zustand/devtools', never]],
  []
> = (set) => ({
  setNavigation: async (previousPath) => {
    set(
      () => ({
        [StateKey]: previousPath,
      }),
      false,
      actionType,
    );
  },
  [StateKey]: {
    previousPath: '',
  },
});
