// Namespace
const customAttributesNamespace = 'motortrend';
const userActionNamespace = '@user/';
const languageTagsActionNamespace = '@languageTags/';
const collectionsActionNamespace = '@collections/';
const configsActionNamespace = '@configs/';
const adConfigNamespace = '@adConfig/';
const jwtConfigNamespace = '@jwtConfig/';
const videoManagerNamespace = '@videoManager/';
const userProfileNamespace = '@userProfile/';
const stickyNamespace = '@sticky/';
const fastTvNamespace = '@fastTv/';
const curatedImagesNamespace = '@curatedImages/';
const zipCodeNamespace = '@zipCode/';
const trendingDataNamespace = '@trendingData/';
const mmyVehicleDataNamespace = '@mmyVehicleData/';
const googlePublisherTagNamespace = '@googlePublisherTag/';
const userEventsNamespace = '@userEvents/';
const navigationNamespace = '@navigation/';
const brandNamespace = '@brand/';
const vehicleTrimSpecs = '@vehicleTrimSpecs/';

// State Keys
export const UserStateKey = 'user';
export const UserProfileStateKey = 'profile';
export const LanguageTagsStateKey = 'languageTags';
export const CollectionsStateKey = 'collections';
export const ConfigsStateKey = 'configs';
export const AdConfigStateKey = 'AdConfig';
export const JwtStateKey = 'userJwt';
export const VideoManagerStateKey = 'VideoManager';
export const StickyStateKey = 'Sticky';
export const FastTvStateKey = 'FastTv';
export const CuratedImagesStateKey = 'CuratedImages';
export const ZipCodeStateKey = 'ZipCode';
export const TrendingDataStateKey = 'TrendingData';
export const MmyVehicleDataStateKey = 'MmyVehicleData';
export const GooglePublisherTagStateKey = 'GooglePublisherTag';
export const UserEventsStateKey = 'UserEvents';
export const NavigationStateKey = 'Navigation';
export const BrandStateKey = 'Brand';
export const VehicleTrimSpecsStateKey = 'VehicleTrimSpecs';

// Action Types
export const getUserDataActionType = `${userActionNamespace}getUserData`;
export const getLanguageTagsActionType = `${languageTagsActionNamespace}getLanguageTags`;
export const getCollectionsActionType = `${collectionsActionNamespace}getCollections`;
export const getConfigsActionType = `${configsActionNamespace}getConfigs`;
export const setAdConfigActionType = `${adConfigNamespace}setAdConfig`;
export const clearAdConfigActionType = `${adConfigNamespace}clearAdConfig`;
export const setZipCodeActionType = `${zipCodeNamespace}setZipCode`;
export const getUserJWTActionType = `${jwtConfigNamespace}getUserJWT`;
export const getVideoManagerActionType = `${videoManagerNamespace}getVideos`;
export const getUserProfileActionType = `${userProfileNamespace}getUserProfile`;
export const clearUserProfileActionType = `${userProfileNamespace}clearUserProfile`;
export const userLogoutActionType = `${userActionNamespace}logoutUser`;
export const getStickyActionType = `${stickyNamespace}getSticky`;
export const setActiveChannelUuidActionType = `${fastTvNamespace}setActiveChannelUuid`;
export const setActiveHlsStreamActionType = `${fastTvNamespace}setActiveHlsStream`;
export const getCuratedImagesActionType = `${curatedImagesNamespace}getCuratedImages`;
export const getTrendingVehiclesActionType = `${trendingDataNamespace}getTrendingVehicles`;
export const setVehicleMakesActionType = `${mmyVehicleDataNamespace}setVehicleMakes`;
export const setYearsByModelActionType = `${mmyVehicleDataNamespace}setYearsByModel`;
export const setModelsByMakeActionType = `${mmyVehicleDataNamespace}setModelsByMake`;
export const getVehicleModelYearsActionType = `${mmyVehicleDataNamespace}getVehicleModelYears`;
export const setGptSlotRenderEndedActionType = `${googlePublisherTagNamespace}setGptSlotRenderEnded`;
export const setGptImpressionViewableTimeActionType = `${googlePublisherTagNamespace}setGptImpressionViewableTime`;
export const resetGptImpressionViewableTimeActionType = `${googlePublisherTagNamespace}resetGptImpressionViewableTime`;
export const setGptRefreshIntervalIdActionType = `${googlePublisherTagNamespace}setGptRefreshIntervalId`;
export const setLastScrollEventActionType = `${userEventsNamespace}setLastScrollEvent`;
export const setNavigationActionType = `${navigationNamespace}setNavigation`;
export const setBrandActionType = `${brandNamespace}setBrand`;
export const setVehicleTrimSpecsActionType = `${vehicleTrimSpecs}setVehicleTrimSpecs`;

// Misc
export const CustomAttributesKey = {
  user: `${customAttributesNamespace}.user`,
};

export enum UserCommentProfileStatus {
  Complete = 'COMPLETE',
  Pending = 'PENDING',
}

export enum SubStateKey {
  Makes = 'makes',
  Models = 'models',
  Years = 'years',
  Undefined = 'undefined',
  LastScroll = 'lastScroll',
  SlotRenderEnded = 'slotRenderEnded',
  ImpressionViewableTime = 'impressionViewableTime',
  RefreshIntervalId = 'refreshIntervalId',
}
