import { type StateCreator } from 'zustand';

import { type UserProfileApiResponse } from '@/types/Comments';

import { fetchUserProfile, updateUserProfile } from '@/lib/interactionApi';
import generateUniqueIdentifier from '@/utils/usernameIdentifierGenerator';

import {
  getUserProfileActionType as actionType,
  clearUserProfileActionType,
  UserProfileStateKey as StateKey,
  UserCommentProfileStatus,
} from '@/store/constants';
import { type UserProfileSlice } from '@/store/typings';

const defaultState = {
  data: {
    avatar: '',
    background: '',
    userName: '',
  },
  status: UserCommentProfileStatus.Pending,
};

export const createUserProfileSlice: StateCreator<
  UserProfileSlice,
  [['zustand/devtools', never]],
  []
> = (set) => ({
  clearUserProfile: () => {
    set(
      () => ({
        [StateKey]: defaultState,
      }),
      false,
      clearUserProfileActionType,
    );
  },
  getUserProfile: async (token: string, username: string | undefined) => {
    const response = (await fetchUserProfile(token)) as UserProfileApiResponse;

    if (response) {
      const updatedResponse = await updateUsernameIfRequired(
        response,
        username,
        token,
      );

      set(
        () => ({
          [StateKey]: {
            data: updatedResponse || response,
            status: UserCommentProfileStatus.Complete,
          },
        }),
        false,
        actionType,
      );
    }
  },
  [StateKey]: defaultState,
});

async function updateUsernameIfRequired(
  response: UserProfileApiResponse,
  username: string | undefined,
  token: string,
): Promise<UserProfileApiResponse | undefined> {
  if (!username || response.userName !== undefined) {
    return;
  }

  const newUserName = generateUniqueIdentifier(username);
  const hasUserNameBeenUpdated = await updateUserProfile(
    { userName: newUserName },
    token,
  );

  if (hasUserNameBeenUpdated === 'success') {
    return { ...response, userName: newUserName };
  }
}
