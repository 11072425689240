import { type StateCreator } from 'zustand';

import {
  setLastScrollEventActionType,
  UserEventsStateKey as StateKey,
  SubStateKey,
} from '@/store/constants';
import { type UserEventsSlice } from '@/store/typings';

export const createUserEventsSlice: StateCreator<
  UserEventsSlice,
  [['zustand/devtools', never]],
  []
> = (set) => ({
  setLastScrollTime: (date) => {
    set(
      () => ({
        [StateKey]: { [SubStateKey.LastScroll]: date },
      }),
      false,
      setLastScrollEventActionType,
    );
  },
  [StateKey]: {
    [SubStateKey.LastScroll]: undefined,
  },
});
