import { env } from '@/config';
import { type StateCreator } from 'zustand';

import {
  setActiveChannelUuidActionType,
  setActiveHlsStreamActionType,
  FastTvStateKey as StateKey,
} from '@/store/constants';
import { type FastTvSlice } from '@/store/typings';

export const createFastTvSlice: StateCreator<
  FastTvSlice,
  [['zustand/devtools', never]],
  []
> = (set) => ({
  setActiveChannelUuid: (activeChannelUuid: string) => {
    set(
      (state) => ({
        [StateKey]: {
          ...state[StateKey],
          activeChannelUuid,
        },
      }),
      false,
      setActiveChannelUuidActionType,
    );
  },
  setActiveHlsStream: (activeHlsStream: string) => {
    set(
      (state) => ({
        [StateKey]: {
          ...state[StateKey],
          activeHlsStream,
        },
      }),
      false,
      setActiveHlsStreamActionType,
    );
  },
  [StateKey]: {
    activeChannelUuid: env.fastTv.channels[0].uuid,
    activeHlsStream: env.fastTv.channels[0].hlsUrl,
  },
});
