import { type StateCreator } from 'zustand';

import {
  getStickyActionType as actionType,
  StickyStateKey as StateKey,
} from '@/store/constants';
import { type StickyData, type StickySlice } from '@/store/typings';

export const createStickySlice: StateCreator<
  StickySlice,
  [['zustand/devtools', never]],
  []
> = (set) => ({
  setStickyData: async (stickyData: StickyData) => {
    set(
      (state) => {
        const prevState = state[StateKey] || {};
        return {
          [StateKey]: {
            ...prevState,
            ...stickyData,
          },
        };
      },
      false,
      actionType,
    );
  },
  [StateKey]: {
    bottomAdhesion: 0,
  },
});
