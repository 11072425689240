import { type StateCreator } from 'zustand';

import {
  clearAdConfigActionType,
  setAdConfigActionType,
  AdConfigStateKey as StateKey,
} from '@/store/constants';
import { type AdConfigSlice } from '@/store/typings';

export const AdConfigInitialState = {
  adPath: '',
  adTargeting: {},
  brand: '',
  defaultVastTemplate: '',
};

export const createAdConfigSlice: StateCreator<
  AdConfigSlice,
  [['zustand/devtools', never]],
  []
> = (set, get) => ({
  setAdConfig: async (adConfig) => {
    const currentConfig = get()[StateKey];

    // Check if the new adConfig is different from the current one
    if (JSON.stringify(currentConfig) !== JSON.stringify(adConfig)) {
      set(
        () => ({
          [StateKey]: adConfig,
        }),
        false,
        setAdConfigActionType,
      );
    }
  },

  clearAdConfig: () => {
    set(
      () => ({
        [StateKey]: AdConfigInitialState,
      }),
      false,
      clearAdConfigActionType,
    );
  },

  [StateKey]: AdConfigInitialState,
});
