import { type StateCreator } from 'zustand';

import {
  setZipCodeActionType as actionType,
  ZipCodeStateKey as StateKey,
} from '@/store/constants';
import { type ZipCodeSlice } from '@/store/typings';

// TODO: explore separating the zip code call from the DTC call.
export const createZipCodeSlice: StateCreator<
  ZipCodeSlice,
  [['zustand/devtools', never]],
  []
> = (set) => ({
  setZipCode: async (zipCode) => {
    set(
      () => ({
        [StateKey]: zipCode,
      }),
      false,
      actionType,
    );
  },
  [StateKey]: {
    zipCode: '',
  },
});
