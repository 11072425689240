import { CommentModalStrings as strings } from '@/translations';

import {
  type Action,
  type ChannelType,
  type CommentAction,
  type LanguageType,
  type ModalConfirmAction,
  type ModalConfirmMessage,
  type ModerationStatus,
} from '@/types/Comments';

import { DataId } from '../nitrous/constants';

export const BASE_VISIBLE_ROOT_COMMENT_COUNT = 6;
export const ADDITIONAL_VISIBLE_ROOT_COMMENT_COUNT = 6;

export const COMMENTS_STORAGE_KEY = 'commentText_';
export const COMMENTS_STORAGE_KEY_ROOT = 'commentText_root_';

export const commentHandlerAction: { [key in CommentAction]: key } = {
  add: 'add',
  delete: 'delete',
  edit: 'edit',
  resend: 'resend',
} as const;

export const channel: { [key in ChannelType]: key } = {
  Reviews: 'Reviews',
} as const;

export const language: { [key in LanguageType]: key } = {
  english: 'english',
} as const;

export const commentAction: { [key in Action]: key } = {
  CREATE: 'CREATE',
  DELETE: 'DELETE',
  UPDATE: 'UPDATE',
} as const;

export const commentModerationStatus: { [key in ModerationStatus]: key } = {
  APPROVED: 'APPROVED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
} as const;

export const commentError = {
  offensiveLanguage: 'Contains offensive language',
  publishError: 'Failed to send comment',
} as const;

export const modalConfirmAction = {
  delete: 'delete',
} as const;

// Maps modal actions (e.g., delete) to the data-ids that will be used for tracking
// the corresponding button to initiate the action. Note that <ConfirmModal> has a
// button for both canceling and confirming a modal action
export const ModalActionDataIdMap: Record<
  keyof typeof modalConfirmAction,
  { cancel: DataId; confirm: DataId }
> = {
  delete: {
    cancel: DataId.DeleteCommentCancelButton,
    confirm: DataId.DeleteCommentConfirmButton,
  },
};

export const modalConfirmMessage: Record<
  ModalConfirmAction,
  ModalConfirmMessage
> = {
  [modalConfirmAction.delete]: {
    buttonText: strings.DeleteCommentMenuButtonText,
    message: strings.DeleteCommentConfirmation,
  },
};
