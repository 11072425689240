import { type StateCreator } from 'zustand';

import { Brand } from '@/lib/gql/graphql';

import {
  setBrandActionType as actionType,
  BrandStateKey as StateKey,
} from '@/store/constants';
import { type BrandSlice } from '@/store/typings';

export const createBrandSlice: StateCreator<
  BrandSlice,
  [['zustand/devtools', never]],
  []
> = (set) => ({
  setBrand: async (brand) => {
    set(
      () => ({
        [StateKey]: { brand },
      }),
      false,
      actionType,
    );
  },
  [StateKey]: {
    brand: Brand.Motortrend,
  },
});
