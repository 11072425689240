import { common } from '@/config';
import SonicApi from '@motortrend/sonic-api';

export default function configureSonicApi(): void {
  if (!SonicApi.initialized) {
    SonicApi.configure({
      appName: common.sonic.appName,
      appPlatform: common.sonic.appPlatform,
      debug: 'true' === process.env['NEXT_PUBLIC_SONIC_API_DEBUG'],
      realm: common.sonic.realm,
      sonicEnvironment: process.env['NEXT_PUBLIC_SONIC_API_ENV'],
      version: process.env['NEXT_PUBLIC_APP_VERSION'] || 'NOT_AVAILABLE',
    });
  }
}
