import { type StateCreator } from 'zustand';

import { createJwt } from '@/lib/interactionApi';

import {
  getUserJWTActionType as actionType,
  JwtStateKey as StateKey,
} from '@/store/constants';
import { type JwtSlice } from '@/store/typings';

export const createJwtSlice: StateCreator<
  JwtSlice,
  [['zustand/devtools', never]],
  []
> = (set) => ({
  getUserJWT: async (userId: string) => {
    const response = await createJwt(userId);
    const key = response as string;

    if (key) {
      set(
        () => ({
          [StateKey]: {
            ...{ key },
          },
        }),
        false,
        actionType,
      );
    }
  },
  [StateKey]: { key: '' },
});
