import { type StateCreator } from 'zustand';

import {
  setVehicleTrimSpecsActionType,
  VehicleTrimSpecsStateKey as StateKey,
} from '@/store/constants';
import { type VehicleTrimSpecsSlice } from '@/store/typings';

export const createVehicleTrimSpecsSlice: StateCreator<
  VehicleTrimSpecsSlice,
  [['zustand/devtools', never]],
  []
> = (set) => ({
  setVehicleTrimSpecs: (selectedTrim, vehicle) => {
    set(
      (state) => ({
        [StateKey]: {
          ...state[StateKey],
          [selectedTrim]: vehicle,
        },
      }),
      false,
      setVehicleTrimSpecsActionType,
    );
  },

  [StateKey]: {},
});
