import { nitrous } from '@motortrend/nitrous-web-sdk';
import SonicApi from '@motortrend/sonic-api';
import { type StateCreator } from 'zustand';

import configureSonicApi from '@/lib/configureSonicApi';

import {
  getUserDataActionType as actionType,
  CustomAttributesKey,
  userLogoutActionType as logoutActionType,
  UserStateKey as StateKey,
} from '@/store/constants';
import {
  type AnonymousTypes,
  type RegisteredTypes,
  type UserData,
  type UserProfileSlice,
  type UserSlice,
} from '@/store/typings';

export const createUserSlice: StateCreator<
  UserSlice & UserProfileSlice,
  [['zustand/devtools', never]],
  [],
  UserSlice
> = (set, get) => ({
  getUserData: () => {
    configureSonicApi();

    SonicApi.me()
      .get()
      .then(async (me) => {
        if (!me?.data?.attributes?.anonymous) {
          const registered = await Promise.all(
            registeredCalls.map((config) => {
              return SonicApi.connect(config, false);
            }),
          );

          const [
            profiles,
            tokens,
            entitlements,
            details,
            subscriptions,
            pendingTerms,
            postalAddresses,
            retentionOffers,
            consents,
            userAttributes,
          ] = registered as RegisteredTypes;

          set(
            () => ({
              [StateKey]: {
                ...{
                  consents,
                  customAttributes: {
                    user: userAttributes,
                  },
                  details,
                  entitlements,
                  me,
                  pendingTerms,
                  postalAddresses,
                  profiles,
                  retentionOffers,
                  subscriptions,
                  tokens,
                },
              },
            }),
            false,
            actionType,
          );
        } else {
          const anonymous = await Promise.all(
            anonymousCalls.map((config) => {
              return SonicApi.connect(config, false);
            }),
          );

          const [profiles, tokens, entitlements] = anonymous as AnonymousTypes;

          set(
            () => ({
              [StateKey]: {
                ...{
                  entitlements,
                  me,
                  profiles,
                  tokens,
                },
              },
            }),
            false,
            actionType,
          );
        }
      });
  },
  logoutUser: async () => {
    interface Response {
      token: string;
    }

    const [response] = await Promise.allSettled([
      SonicApi.connect({
        method: 'POST',
        url: '/logout/',
      }) as Promise<Response>,
      nitrous.user.logout(),
    ]);

    const logoutSucceeded =
      response.status === 'fulfilled' && !!response.value.token;

    if (logoutSucceeded) {
      // clear commenting profile
      get().clearUserProfile();

      set(
        () => ({
          [StateKey]: {},
        }),
        false,
        logoutActionType,
      );
    }
  },
  [StateKey]: {} as UserData,
});

const anonymousCalls = [
  { url: '/users/me/profiles' },
  { url: '/users/me/tokens' },
  { url: '/entitlements/userEntitlementsSummary/me' },
];

const registeredCalls = [
  ...anonymousCalls,
  { url: '/users/me/details' },
  { url: '/monetization/subscriptions?include=pricePlan,paymentMethod' },
  { url: '/legal/pendingTerms' },
  { url: '/address/postalAddresses' },
  { url: '/offers/retentionOffers' },
  { url: '/legal/consents?include=term' },
  { url: `/users/me/customAttributes/${CustomAttributesKey.user}` },
];
