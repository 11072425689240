import crypto from 'crypto';

export default function generateUniqueIdentifier(username: string) {
  if (!username || username.trim().length === 0) {
    throw new Error('Username cannot be empty.');
  }

  if (username.length < 3) {
    throw new Error('Username must be at least 3 characters long.');
  }

  const randomBytes = crypto.randomBytes(3); // Generate 3 cryptographically secure random bytes
  const randomValue = randomBytes.readUIntBE(0, 3); // Convert bytes to an integer value
  const randomDigits = String(100000 + (randomValue % 900000)); // Ensure it's a 6-digit number

  return `${username.substring(0, 3)}${randomDigits}`;
}
